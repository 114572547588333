import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'
// @mui
import { useTheme } from '@mui/material/styles'
import { Box, Link, Typography } from '@mui/material'

import useResponsive from '../../hooks/useResponsive'
// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const isDesktop = useResponsive('up', 'lg')

  const theme = useTheme()

  const PRIMARY_LIGHT = theme.palette.primary.light

  const PRIMARY_MAIN = theme.palette.primary.main

  const PRIMARY_DARK = theme.palette.primary.dark

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      component="div"
      sx={{
        // width: 300,
        height: 40,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <img
        // src={process.env.PUBLIC_URL + '/assets/images/vendor-portal-logo.png'}
        // src={process.env.PUBLIC_URL + '/assets/images/sanfield-logo.png'}
        src={process.env.PUBLIC_URL + '/assets/images/' + process.env.REACT_APP_SANFIELD_LOGO + '.png'}
        alt="Vendor Patal"
        loading="lazy"
      />
    </Box>
  )

  if (disabledLink) {
    return <>{logo}</>
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  )
})

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
}

export default Logo
